import { ApiService } from "@/services/api/ApiService";
import {
  TAdminTotalDataResponse,
  TChartDataAdminResponse,
  TChartDataResponse,
  TEndpointsDataResponse,
  TPayMethodStatisticResponse,
  TTotalData,
  TTotalDataFilter,
  TTotalStatisticResponse,
} from "@/types/dashboard";

export class Dashboard extends ApiService {
  public total(filter: TTotalDataFilter) {
    return this.instance.get<TTotalData>("/merchant/dashboard/total_data/", {
      params: filter,
    });
  }

  public chart(filter: TTotalDataFilter) {
    return this.instance.get<TChartDataResponse>(
      "/merchant/dashboard/total_chart_data/",
      {
        params: filter,
      }
    );
  }

  public endpoints(filter: TTotalDataFilter) {
    return this.instance.get<TEndpointsDataResponse>(
      "/merchant/dashboard/endpoints_data/",
      {
        params: filter,
      }
    );
  }

  public totalDataAdmin(filter: TTotalDataFilter) {
    return this.instance.get<TAdminTotalDataResponse>(
      "/admin/dashboard/total_data/",
      {
        params: filter,
      }
    );
  }

  public totalChartDataAdmin(filter: TTotalDataFilter) {
    return this.instance.get<TChartDataAdminResponse>(
      "/admin/dashboard/total_chart_data/",
      {
        params: filter,
      }
    );
  }

  public totalStatisticAdmin(filter: TTotalDataFilter) {
    return this.instance.get<TTotalStatisticResponse>(
      "/admin/dashboard/total_statistic/",
      {
        params: filter,
      }
    );
  }

  public payMethodStatisticAdmin(filter: TTotalDataFilter) {
    return this.instance.get<TPayMethodStatisticResponse>(
      "/admin/dashboard/method_statistic/",
      {
        params: filter,
      }
    );
  }
}
