import { ApiService } from "@/services/api/ApiService";
import { TApply2FACode, TResponse2FA } from "@/types/common";

export class GoogleAuthenticator extends ApiService {
  public configure() {
    return this.instance.get<TResponse2FA>("auth/2fa/configure/");
  }

  public apply(form: TApply2FACode) {
    return this.instance.post<TResponse2FA>("auth/2fa/apply/", form);
  }

  public reset() {
    return this.instance.post("auth/2fa/reset/");
  }
}
