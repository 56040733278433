import axios from "axios";
import { Auth } from "@/services/api/Auth";
import { Payment } from "@/services/api/Payment";
import { Common } from "@/services/api/Common";
import { User } from "@/services/api/User";
import { Shops } from "@/services/api/Shops";
import { Registers } from "@/services/api/Registers";
import { PaymentGate } from "@/services/api/PaymentGate";
import { Payout } from "@/services/api/Payout";
import { Merchant } from "@/services/api/Merchant";
import { Dashboard } from "@/services/api/Dashboard";
import { GoogleAuthenticator } from "@/services/api/GoogleAuthenticator";
import { Endpoint } from "@/services/api/Endpoint";
import { Provider } from "@/services/api/Provider";
import { Reset } from "@/services/api/Reset";
import { Finance } from "@/services/api/Finance";
import { Search } from "@/services/api/Search";

const API_URL = process.env.VUE_APP_API_URL || "";
const baseURL = `${API_URL}/api/v1/`;

export const useApi = () => {
  const apiInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: false,
    baseURL,
  });

  apiInstance.interceptors.request.use(function (config: any) {
    if (config.url !== "auth/login/") {
      config.headers.Authorization = localStorage.getItem("token");
    }
    return config;
  });

  return {
    auth: new Auth(apiInstance),
    paymentService: new Payment(apiInstance),
    payoutService: new Payout(apiInstance),
    commonService: new Common(apiInstance),
    userService: new User(apiInstance),
    shopService: new Shops(apiInstance),
    registerService: new Registers(apiInstance),
    paymentGateService: new PaymentGate(apiInstance),
    merchantService: new Merchant(apiInstance),
    dashboardService: new Dashboard(apiInstance),
    authenticator: new GoogleAuthenticator(apiInstance),
    endpointsService: new Endpoint(apiInstance),
    providerService: new Provider(apiInstance),
    resetService: new Reset(apiInstance),
    financeService: new Finance(apiInstance),
    searchService: new Search(apiInstance),
  };
};
