import { ApiService } from "@/services/api/ApiService";
import {
  TPaymentFilters,
  TPaymentRequest,
  TPaymentLog,
  TRefundPayment,
  TCancelPayment,
  TPayment,
} from "@/types/payment";
import { TAggregateRating, TFeeResponse } from "@/types/common";
import Qs from "qs";

export class Payment extends ApiService {
  public prepareParams(filters: TPaymentFilters, isAdmin = false) {
    const card = filters.card
      ?.trim()
      ?.replace(/\*{1,}/g, "|")
      .split("|");
    return {
      ...filters,
      amount_from: filters.amount_from ? filters.amount_from * 100 : null,
      amount_to: filters.amount_to ? filters.amount_to * 100 : null,
      date_from: filters.date_from
        ? isAdmin
          ? filters.date_from.toISOString()
          : filters.date_from.format("YYYY-MM-DD")
        : null,
      date_to: filters.date_to
        ? isAdmin
          ? filters.date_to.toISOString()
          : filters.date_to.format("YYYY-MM-DD")
        : null,
      card: null,
      card_bin: card && card[0] ? card[0] : null,
      card_pan: card && card[1] ? card[1] : null,
    };
  }

  public list(
    page = 1,
    filters: TPaymentFilters,
    isAdmin = false,
    ordering: string | null
  ) {
    return this.instance.get<TPaymentRequest>(
      `${isAdmin ? "admin" : "merchant"}/payment/`,
      {
        params: {
          page,
          ...this.prepareParams(filters, isAdmin),
          ordering,
        },
        paramsSerializer: (params) =>
          Qs.stringify(params, { indices: false, skipNulls: true }),
      }
    );
  }

  public aggregate(filters: TPaymentFilters, isAdmin = false) {
    return this.instance.get<TAggregateRating[]>(
      `${isAdmin ? "admin" : "merchant"}/payment/aggregate/`,
      {
        params: this.prepareParams(filters),
        paramsSerializer: (params) =>
          Qs.stringify(params, { indices: false, skipNulls: true }),
      }
    );
  }

  public logs(id: string, isAdmin = false) {
    return this.instance.get<TPaymentLog[]>(
      `${isAdmin ? "admin" : "merchant"}/logs/${id}/transaction/`
    );
  }

  public last() {
    return this.instance.get<TPaymentRequest>(`merchant/payment/`);
  }

  public refund(form: TRefundPayment) {
    return this.instance.post("paymentgate/payment/refund/", form);
  }

  public cancel(form: TCancelPayment) {
    return this.instance.post("paymentgate/payment/cancel/", form);
  }

  public item(id: number) {
    return this.instance.get<TPayment>(`admin/payment/${id}/`);
  }

  public fee(payment_id: number) {
    return this.instance.get<TFeeResponse>(`/admin/payment_fee/`, {
      params: {
        payment_id,
      },
    });
  }
}
