import { ApiService } from "@/services/api/ApiService";
import { TModule, TRegister, TRegisterForm } from "@/types/registers.ts";
import { useAuth } from "@/use/useAuth";

export class Registers extends ApiService {
  public modules() {
    return this.instance.get<TModule[]>("merchant/registrysettings/modules/");
  }

  public save(form: TRegister, registry?: TRegister) {
    if (registry) {
      return this.instance.put(
        `merchant/registrysettings/${registry.id}/`,
        form
      );
    }
    return this.instance.post("merchant/registrysettings/", form);
  }

  public delete(id: number) {
    return this.instance.delete(`merchant/registrysettings/${id}/`);
  }

  public sendRegister(
    form: TRegisterForm,
    type: "payment" | "payout" = "payment"
  ) {
    const { isAdmin } = useAuth();
    return this.instance.post(
      isAdmin.value
        ? `admin/registrysettings/send_${type}_register/`
        : `merchant/${type}/sending_register/`,
      form
    );
  }
}
