import { ApiService } from "@/services/api/ApiService";
import {
  TMerchantFilter,
  TMerchantReportForm,
  TMerchantRequest,
  TMerchantUpdateForm,
} from "@/types/merchant";
import { TMerchant } from "@/types/common";

export class Merchant extends ApiService {
  public search(title = "") {
    return this.instance.get<TMerchantRequest>("admin/merchant/", {
      params: {
        title,
      },
    });
  }

  public merchant(id: number) {
    return this.instance.get<TMerchant>(`merchant/merchant/${id}/`);
  }

  public update(form: TMerchant, id: number) {
    return this.instance.put<TMerchant>(`merchant/merchant/${id}/`, form);
  }

  public list(page = 1, filters: TMerchantFilter) {
    return this.instance.get<TMerchantRequest>(`admin/merchant/`, {
      params: {
        page,
        ...filters,
      },
    });
  }

  public sendRegistry(filters: TMerchantReportForm) {
    return this.instance.post(
      `admin/merchant/${filters.id}/register_export/`,
      filters
    );
  }

  public loginAsMerchant(id: number) {
    return this.instance.get<{ token: string }>(
      `admin/merchant/${id}/auth/token/`
    );
  }

  public updateMerchantByAdmin(id: number, form: TMerchantUpdateForm) {
    return this.instance.put(`admin/merchant/${id}/`, form);
  }
}
