<template>
  <svg style="display: none">
    <symbol
      id="logo"
      width="213"
      height="20"
      viewBox="0 0 213 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.71794 2.71026L5.98746 1.96894L7.48138 1.16796C9.46549 0.104153 11.8525 0.106658 13.8161 1.20776C14.7606 1.73739 15.836 2.34738 16.924 2.97866C17.4696 3.30214 26.0789 8.26606 29.0347 9.97002C29.6502 10.3249 30.4014 10.3245 31.0199 9.9749L39.0737 5.42194C39.1796 5.42203 39.2042 8.73543 39.2061 11.5262C39.2078 13.8749 37.9617 16.0432 35.9418 17.2418L33.4083 18.7452C31.3198 19.9845 28.7241 19.9957 26.6253 18.774C25.0871 17.8785 23.528 16.9705 23.1511 16.7499C22.3546 16.2836 20.6603 15.2961 19.3859 14.5554C18.1115 13.8149 15.643 12.3779 13.9006 11.3622C12.9927 10.8331 12.1252 10.3541 11.5024 10.0166C10.9171 9.69943 10.2132 9.70462 9.63021 10.0261C8.78193 10.4938 7.44796 11.239 6.01903 12.0743C3.61172 13.4815 1.57688 14.6329 1.49709 14.6329C1.40252 14.6329 1.36545 12.896 1.39036 9.63201L1.39943 8.44026C1.41742 6.07861 2.67847 3.90117 4.71794 2.71026Z"
      />
      <path
        d="M25.7579 1.76454C26.1345 1.54667 26.6081 1.27528 27.1018 0.993534C28.9416 -0.0565458 31.1237 -0.066209 32.9474 1.01147C33.0986 1.10082 33.2519 1.19182 33.4064 1.28396C34.377 1.8628 35.606 2.56854 36.1378 2.85204C36.6694 3.13554 37.141 3.42722 37.1856 3.50004C37.2302 3.57299 36.1761 4.26091 34.8431 5.02894C33.5101 5.79697 32.1914 6.56061 31.9126 6.72612C31.7501 6.82253 31.1045 7.1708 30.5944 7.44449C30.1841 7.66459 29.6892 7.65424 29.2877 7.41866L26.3612 5.70164C23.6085 4.08662 22.8299 3.56715 22.9697 3.43892C23.0695 3.34725 24.3242 2.59384 25.7579 1.76454Z"
      />
      <path
        d="M3.50238 16.4605C3.51585 16.3801 5.07496 15.4084 6.96701 14.3012C8.17436 13.5946 9.34238 12.9861 10.0296 12.6381C10.4203 12.4404 10.8796 12.454 11.2608 12.6694C12.9707 13.6358 17.7078 16.3251 17.7102 16.4605C17.7115 16.5348 15.8285 17.6417 14.0115 18.6924C11.9534 19.8825 9.41155 19.8804 7.32644 18.7382C5.86528 17.9379 4.49259 17.1916 4.2634 17.0595C3.83141 16.8104 3.48891 16.5409 3.50238 16.4605Z"
      />
      <path
        d="M56.345 15.6662C57.973 15.6662 59.3012 15.0235 60.051 13.9524H60.2224L60.6937 15.3449H62.7716V4.48383H60.7151L60.2652 5.85485H60.0724C59.3441 4.80516 57.9945 4.1625 56.3664 4.1625C52.896 4.1625 50.7109 6.81884 50.7109 10.0536C50.7109 13.117 52.896 15.6662 56.345 15.6662ZM56.7306 12.8385C55.1025 12.8385 53.7957 11.6388 53.7957 9.92505C53.7957 8.16844 55.1025 6.99022 56.7091 6.99022C58.3586 6.99022 59.6868 8.27555 59.6868 9.92505C59.6868 11.5103 58.4015 12.8385 56.7306 12.8385Z"
      />
      <path
        d="M65.3058 15.3449H68.5191V9.71083C68.5191 8.27555 69.2689 7.03307 70.8541 7.03307C72.2251 7.03307 73.0606 7.84711 73.0606 9.3895V15.3449H76.2739V8.70399C76.2739 5.98338 74.7315 4.1625 71.7753 4.1625C69.9972 4.1625 68.9904 4.86943 68.3691 5.72632H68.1763L67.8764 4.48383H65.3058V15.3449Z"
      />
      <path
        d="M79.8385 19.7364H83.0518L84.7013 15.7305L89.1571 4.48383H85.7724L83.3303 11.9816H83.1589L80.5669 4.48383H77.1822L81.5094 15.3449L79.8385 19.7364Z"
      />
      <path
        d="M90.5353 19.7364H93.6844V14.3166H93.8558C94.3699 15.0021 95.591 15.6662 97.0477 15.6662C100.24 15.6662 102.617 13.1598 102.617 9.90363C102.617 6.69031 100.454 4.1625 97.0905 4.1625C95.1625 4.1625 93.8772 4.9337 93.3416 5.74774H93.1703L92.8489 4.48383H90.5353V19.7364ZM96.5978 12.8385C94.9055 12.8385 93.6201 11.5103 93.6201 9.92505C93.6201 8.27555 94.9483 6.99022 96.5978 6.99022C98.2259 6.99022 99.5112 8.16844 99.5112 9.92505C99.5112 11.6388 98.2045 12.8385 96.5978 12.8385Z"
      />
      <path
        d="M109.607 15.6662C111.235 15.6662 112.564 15.0235 113.313 13.9524H113.485L113.956 15.3449H116.034V4.48383H113.978L113.528 5.85485H113.335C112.606 4.80516 111.257 4.1625 109.629 4.1625C106.158 4.1625 103.973 6.81884 103.973 10.0536C103.973 13.117 106.158 15.6662 109.607 15.6662ZM109.993 12.8385C108.365 12.8385 107.058 11.6388 107.058 9.92505C107.058 8.16844 108.365 6.99022 109.972 6.99022C111.621 6.99022 112.949 8.27555 112.949 9.92505C112.949 11.5103 111.664 12.8385 109.993 12.8385Z"
      />
      <path
        d="M120.068 19.7364H123.281L124.931 15.7305L129.386 4.48383H126.002L123.56 11.9816H123.388L120.796 4.48383H117.411L121.739 15.3449L120.068 19.7364Z"
      />
      <path
        d="M140.172 15.6662C141.8 15.6662 143.128 15.0235 143.878 13.9524H144.049L144.52 15.3449H146.598V4.48383H144.542L144.092 5.85485H143.899C143.171 4.80516 141.821 4.1625 140.193 4.1625C136.723 4.1625 134.538 6.81884 134.538 10.0536C134.538 13.117 136.723 15.6662 140.172 15.6662ZM140.557 12.8385C138.929 12.8385 137.622 11.6388 137.622 9.92505C137.622 8.16844 138.929 6.99022 140.536 6.99022C142.185 6.99022 143.513 8.27555 143.513 9.92505C143.513 11.5103 142.228 12.8385 140.557 12.8385Z"
      />
      <path
        d="M149.132 15.3449H152.346V9.71083C152.346 8.27555 153.095 7.03307 154.681 7.03307C156.052 7.03307 156.887 7.84711 156.887 9.3895V15.3449H160.101V8.70399C160.101 5.98338 158.558 4.1625 155.602 4.1625C153.824 4.1625 152.817 4.86943 152.196 5.72632H152.003L151.703 4.48383H149.132V15.3449Z"
      />
      <path
        d="M163.665 19.7364H166.878L168.528 15.7305L172.984 4.48383H169.599L167.157 11.9816H166.986L164.393 4.48383H161.009L165.336 15.3449L163.665 19.7364Z"
      />
      <path
        d="M178.916 15.6662C180.544 15.6662 181.872 15.0235 182.622 13.9524H182.793L183.264 15.3449H185.342V0.263672H182.215V5.55494H182.022C181.358 4.76232 180.415 4.1625 178.937 4.1625C175.467 4.1625 173.281 6.81884 173.281 10.0536C173.281 13.117 175.467 15.6662 178.916 15.6662ZM179.301 12.8385C177.673 12.8385 176.366 11.6388 176.366 9.92505C176.366 8.16844 177.673 6.99022 179.28 6.99022C180.929 6.99022 182.257 8.27555 182.257 9.92505C182.257 11.5103 180.972 12.8385 179.301 12.8385Z"
      />
      <path
        d="M192.932 15.6662C194.56 15.6662 195.888 15.0235 196.638 13.9524H196.809L197.281 15.3449H199.359V4.48383H197.302L196.852 5.85485H196.659C195.931 4.80516 194.581 4.1625 192.953 4.1625C189.483 4.1625 187.298 6.81884 187.298 10.0536C187.298 13.117 189.483 15.6662 192.932 15.6662ZM193.318 12.8385C191.689 12.8385 190.383 11.6388 190.383 9.92505C190.383 8.16844 191.689 6.99022 193.296 6.99022C194.946 6.99022 196.274 8.27555 196.274 9.92505C196.274 11.5103 194.988 12.8385 193.318 12.8385Z"
      />
      <path
        d="M203.392 19.7364H206.606L208.255 15.7305L212.711 4.48383H209.326L206.884 11.9816H206.713L204.121 4.48383H200.736L205.063 15.3449L203.392 19.7364Z"
      />
    </symbol>

    <symbol
      id="logo-small"
      width="40"
      height="20"
      viewBox="0 0 40 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.71794 2.71026L5.98746 1.96894L7.48138 1.16796C9.46549 0.104153 11.8525 0.106658 13.8161 1.20776C14.7606 1.73739 15.836 2.34738 16.924 2.97866C17.4696 3.30214 26.0789 8.26606 29.0347 9.97002C29.6502 10.3249 30.4014 10.3245 31.0199 9.9749L39.0737 5.42194C39.1796 5.42203 39.2042 8.73543 39.2061 11.5262C39.2078 13.8749 37.9617 16.0432 35.9418 17.2418L33.4083 18.7452C31.3198 19.9845 28.7241 19.9957 26.6253 18.774C25.0871 17.8785 23.528 16.9705 23.1511 16.7499C22.3546 16.2836 20.6603 15.2961 19.3859 14.5554C18.1115 13.8149 15.643 12.3779 13.9006 11.3622C12.9927 10.8331 12.1252 10.3541 11.5024 10.0166C10.9171 9.69943 10.2132 9.70462 9.63021 10.0261C8.78193 10.4938 7.44796 11.239 6.01903 12.0743C3.61172 13.4815 1.57688 14.6329 1.49709 14.6329C1.40252 14.6329 1.36545 12.896 1.39036 9.63201L1.39943 8.44026C1.41742 6.07861 2.67847 3.90117 4.71794 2.71026Z"
      />
      <path
        d="M25.7579 1.76454C26.1345 1.54667 26.6081 1.27528 27.1018 0.993534C28.9416 -0.0565458 31.1237 -0.066209 32.9474 1.01147C33.0986 1.10082 33.2519 1.19182 33.4064 1.28396C34.377 1.8628 35.606 2.56854 36.1378 2.85204C36.6694 3.13554 37.141 3.42722 37.1856 3.50004C37.2302 3.57299 36.1761 4.26091 34.8431 5.02894C33.5101 5.79697 32.1914 6.56061 31.9126 6.72612C31.7501 6.82253 31.1045 7.1708 30.5944 7.44449C30.1841 7.66459 29.6892 7.65425 29.2877 7.41866L26.3612 5.70164C23.6085 4.08662 22.8299 3.56715 22.9697 3.43892C23.0695 3.34725 24.3242 2.59384 25.7579 1.76454Z"
      />
      <path
        d="M3.50238 16.4605C3.51585 16.3801 5.07496 15.4084 6.96701 14.3012C8.17436 13.5946 9.34238 12.9861 10.0296 12.6381C10.4203 12.4404 10.8796 12.454 11.2608 12.6694C12.9707 13.6358 17.7078 16.3251 17.7102 16.4605C17.7115 16.5348 15.8285 17.6417 14.0115 18.6924C11.9534 19.8825 9.41155 19.8804 7.32644 18.7382C5.86528 17.9379 4.49259 17.1916 4.2634 17.0595C3.83141 16.8104 3.48891 16.5409 3.50238 16.4605Z"
      />
    </symbol>

    <symbol
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="success-payment"
    >
      <path
        d="M16 53.9L51.8977 89L107.125 35"
        stroke="#CADE3D"
        stroke-width="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>

    <symbol
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="fail-payment"
    >
      <path
        d="M35.0472 35.2567L89 89M35 88.7433L88.9528 35"
        stroke="#F05860"
        stroke-width="10"
        stroke-linecap="round"
      />
    </symbol>
  </svg>
</template>
