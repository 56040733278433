import { ApiService } from "@/services/api/ApiService";
import { TShop, TShopResponse, TStatusOption } from "@/types/merchant";
import { TFormPayment, TPaymentResponse } from "@/types/common";

export class Shops extends ApiService {
  public load(isAdmin = false, page = 1) {
    return this.instance.get<TShopResponse>(
      `${isAdmin ? "admin" : "merchant"}/endpoint/`,
      {
        params: {
          page,
        },
      }
    );
  }

  public save(shop: TShop, merchant?: number) {
    if (shop.id) {
      return this.instance.put(`merchant/endpoint/${shop.id}/`, shop);
    }

    return this.instance.post<TShop>("merchant/endpoint/", {
      ...shop,
      ...(merchant ? { merchant } : {}),
    });
  }

  public shop(id: number) {
    return this.instance.get(`merchant/endpoint/${id}/`);
  }

  public delete(id: number) {
    return this.instance.delete(`merchant/endpoint/${id}/`);
  }

  public statuses() {
    return this.instance.get<TStatusOption[]>("merchant/endpoint/statuses/");
  }

  public createPayment(form: TFormPayment) {
    return this.instance.post<TPaymentResponse>(
      "paymentgate/payment/simple/",
      form
    );
  }
}
