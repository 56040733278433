import { ApiService } from "@/services/api/ApiService";
import { TSearchRequest } from "@/types/search";

export class Search extends ApiService {
  public search(search = "") {
    return this.instance.get<TSearchRequest>("admin/search/", {
      params: {
        search,
      },
    });
  }
}
