import { ApiService } from "@/services/api/ApiService";
import { TPayment, TPaymentRequest, TPayoutFilters } from "@/types/payment";
import { TAggregateRating, TFeeResponse } from "@/types/common";
import Qs from "qs";

export class Payout extends ApiService {
  public prepareParams(filters: TPayoutFilters, isAdmin = false) {
    return {
      ...filters,
      amount_from: filters.amount_from ? filters.amount_from * 100 : null,
      amount_to: filters.amount_to ? filters.amount_to * 100 : null,
      date_from: filters.date_from
        ? isAdmin
          ? filters.date_from.toISOString()
          : filters.date_from.format("YYYY-MM-DD")
        : null,
      date_to: filters.date_to
        ? isAdmin
          ? filters.date_to.toISOString()
          : filters.date_to.format("YYYY-MM-DD")
        : null,
    };
  }

  public list(
    page = 1,
    filters: TPayoutFilters,
    isAdmin = false,
    ordering: string | null
  ) {
    return this.instance.get<TPaymentRequest>(
      `${isAdmin ? "admin" : "merchant"}/payout/`,
      {
        params: {
          page,
          ...this.prepareParams(filters),
          ordering,
        },
        paramsSerializer: (params) =>
          Qs.stringify(params, { indices: false, skipNulls: true }),
      }
    );
  }

  public aggregate(filters: TPayoutFilters, isAdmin = false) {
    return this.instance.get<TAggregateRating[]>(
      `${isAdmin ? "admin" : "merchant"}/payout/aggregate/`,
      {
        params: this.prepareParams(filters),
        paramsSerializer: (params) =>
          Qs.stringify(params, { indices: false, skipNulls: true }),
      }
    );
  }

  public item(id: number) {
    return this.instance.get<TPayment>(`admin/payout/${id}/`);
  }

  public fee(payout_id: number) {
    return this.instance.get<TFeeResponse>(`/admin/payout_fee/`, {
      params: {
        payout_id,
      },
    });
  }
}
