import { ApiService } from "@/services/api/ApiService";
import { TRestoreForm } from "@/types/common";

export class Reset extends ApiService {
  public preReset(form: TRestoreForm) {
    return this.instance.post("/auth/restore/pre/", form);
  }

  public verify(form: TRestoreForm) {
    return this.instance.post("/auth/restore/verify/", form);
  }

  public save(form: TRestoreForm) {
    return this.instance.post("/auth/restore/", form);
  }
}
