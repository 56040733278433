import { ApiService } from "@/services/api/ApiService";
import { TCurrency, TPaymentType } from "@/types/common";
import { TLanguageInfo } from "@/types/merchant";

export class Common extends ApiService {
  public currencies() {
    return this.instance.get<TCurrency[]>("common/currency/");
  }

  public languages() {
    return this.instance.get<TLanguageInfo[]>("localization/language/");
  }

  public paymentTypes() {
    return this.instance.get<TPaymentType[]>("common/means-of-payment-type/");
  }
}
