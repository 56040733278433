import { useApi } from "@/use/useApi";
import { ref } from "vue";
import { TCurrency, TPaymentType } from "@/types/common";
import { TLanguageInfo } from "@/types/merchant";

const currencies = ref<TCurrency[]>([]);
const languages = ref<TLanguageInfo[]>([]);
const paymentTypes = ref<TPaymentType[]>([]);

export const useCommon = () => {
  const { commonService } = useApi();

  const init = () => {
    commonService.currencies().then(({ data }) => {
      currencies.value = data;
    });
    commonService.languages().then(({ data }) => {
      languages.value = data;
    });
    commonService.paymentTypes().then(({ data }) => {
      paymentTypes.value = data;
    });
  };

  const currencyById = (id?: number) => {
    return (
      currencies.value.find((currency) => currency.id === id)?.num_code ||
      currencies.value[0].num_code
    );
  };

  return {
    currencies,
    languages,
    paymentTypes,
    init,
    currencyById,
  };
};
