import { ApiService } from "@/services/api/ApiService";
import { TPayOut } from "@/types/paymentGate";
import { TPaymentGate } from "@/types/common";
export class PaymentGate extends ApiService {
  public pay(form: TPayOut) {
    return this.instance.post("paymentgate/payout/simple/", form);
  }

  public modules() {
    return this.instance.get<TPaymentGate[]>(
      "/merchant/endpointpaymentgatewaysettings/modules/"
    );
  }
}
