import { computed, reactive, ref } from "vue";
import { TErrors, TFormAuth, TNullable, TUser } from "@/types/common";
import { useApi } from "@/use/useApi";
import { useRouter } from "vue-router";
import { Modal } from "ant-design-vue";
import { useModal } from "@/use/useModal";
import { useAuthenticator } from "@/use/useAuthenticator";
const required2FA = ref<boolean>(false);
const user = ref<TNullable<TUser>>(null);
const loading = ref(true);

export const useAuth = () => {
  const { auth } = useApi();
  const { modalType } = useModal();
  const router = useRouter();
  const { configure } = useAuthenticator();

  const authForm = reactive<TFormAuth>({
    email: "",
    password: "",
    remember: true,
    totp_code: "",
    node: process.env.VUE_APP_NODE_ID || 1,
  });

  const errors = reactive<TErrors>({
    email: [],
    password1: [],
    totp_code: [],
  });

  const disabled = ref<boolean>(false);

  const resetErrors = () => {
    errors.email = [];
    errors.password1 = [];
  };

  const onAuth = () => {
    resetErrors();
    if (authForm.email.trim() === "") {
      errors?.email?.push("Field required");
      return;
    }
    if (authForm.password.trim() === "") {
      errors?.password1?.push("Field required");
      return;
    }
    disabled.value = true;

    auth
      .login(authForm)
      .then(({ data }) => {
        localStorage.setItem("token", `Token ${data.token}`);
        loadUser();
        router.push({ name: "main" }).then();
        modalType.value = null;
        required2FA.value = false;
        configure();
      })
      .catch(({ response }) => {
        disabled.value = false;
        if (response.data.totp_code) {
          if (required2FA.value) {
            errors.totp_code = response.data.totp_code;
            return;
          }
          required2FA.value = true;
        } else {
          Modal.error({
            title: "Incorrect login or password",
          });
        }
      })
      .finally(() => {
        disabled.value = false;
      });
  };

  const loadUser = () => {
    return auth
      .user()
      .then(({ data }) => {
        user.value = data;
      })
      .catch(() => {
        localStorage.removeItem("user");
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const onLogOut = () => {
    const adminToken = localStorage.getItem("admin.token");
    if (adminToken) {
      localStorage.getItem("token");
      localStorage.removeItem("admin.token");
      localStorage.setItem("token", adminToken);
      loadUser().then(() => {
        router.push({ name: "main" }).then();
      });
      return;
    }
    localStorage.removeItem("token");
    user.value = null;
    router.push({ name: "home" }).then();
  };

  const userName = computed(() => user.value?.username);

  const isAdmin = computed(() => user.value?.is_superuser);

  return {
    authForm,
    errors,
    disabled,
    userName,
    required2FA,
    isAdmin,
    user,
    loading,
    onAuth,
    onLogOut,
    loadUser,
  };
};
