import { ref, watch } from "vue";

export enum EModalType {
  AUTH = "AUTH",
  REGISTER = "REGISTER",
  RESET = "RESET",
  COMPLETE_RESET = "COMPLETE_RESET",
}

const modalType = ref<EModalType | null>(null);
const isModalTermsVisible = ref<boolean>(false);

export const useModal = () => {
  watch(
    () => modalType.value,
    (value) => {
      const element = document.getElementsByTagName("html")[0];
      if (value) {
        element.classList.add("modal-overflow");
      } else {
        element.classList.remove("modal-overflow");
      }
    }
  );

  const onOpenContacts = () => {
    modalType.value = null;

    const element = document.getElementById("resources");
    if (element) {
      element.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  };

  return {
    modalType,
    isModalTermsVisible,
    onOpenContacts,
  };
};
