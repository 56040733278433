import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: () => import("@/layouts/LandingLayout.vue"),

    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/Home/HomePage.vue"),
      },
      {
        path: "/resources",
        name: "resources",
        component: () => import("@/views/Resources/ResourcesPage.vue"),
      },
      /*{
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('@/views/Terms/PrivacyPolicyPage.vue'),
      },
      {
        path: '/terms-of-service',
        name: 'terms-of-service',
        component: () => import('@/views/Terms/TermsDataPage.vue'),
      },*/
    ],
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/DashBoard/DashBoardPage.vue"),

    children: [
      {
        path: "/main",
        name: "main",
        component: () => import("@/views/DashBoard/DashBoard.vue"),
      },
      {
        path: "/endpoints",
        name: "endpoints",
        component: () => import("@/views/Shops/MerchantShops.vue"),
      },
      {
        path: "/payments",
        name: "payments",
        component: () => import("@/views/Payments/PaymentsPage.vue"),
      },
      {
        path: "/payments/:id",
        name: "payment",
        component: () => import("@/views/Payments/PaymentPage.vue"),
      },
      {
        path: "/payouts",
        name: "payouts",
        component: () => import("@/views/Payout/PayoutsPage.vue"),
      },
      {
        path: "/payouts/:id",
        name: "payout",
        component: () => import("@/views/Payout/PayoutPage.vue"),
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/Settings/ProfilePage.vue"),
      },
      {
        path: "/support",
        name: "support",
        component: () => import("@/views/Support/SupportPage.vue"),
      },
      {
        path: "/merchants",
        name: "merchants",
        component: () => import("@/views/Merchants/MerchantsPage.vue"),
      },
      {
        path: "/finance",
        name: "finance",
        component: () => import("@/views/Finances/FinancesPage.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
