import { ApiService } from "@/services/api/ApiService";
import {
  TFormAuth,
  TPasswordReset,
  TProfile,
  TRegisterForm,
} from "@/types/common";

export class Auth extends ApiService {
  public login(form: TFormAuth) {
    return this.instance.post("auth/login/", form);
  }

  public user() {
    return this.instance.get("auth/user/");
  }

  public changePassword(form: TPasswordReset) {
    return this.instance.post("auth/password/", form);
  }

  public changeProfile(form: TProfile) {
    return this.instance.post("auth/profile/", form);
  }

  public preRegister(form: TRegisterForm) {
    return this.instance.post("auth/register/pre/", {
      email: form.email,
    });
  }

  public register(form: TRegisterForm) {
    return this.instance.post("auth/register/", form);
  }

  public registerModerate(form: TRegisterForm) {
    return this.instance.post("auth/register-merchant-with-moderate/", form);
  }
}
