import { useApi } from "@/use/useApi";
import { reactive, ref } from "vue";
import { TResponse2FA } from "@/types/common";
const mustRemove = ref<boolean>(false);

const response2FA = reactive<TResponse2FA>({
  qr_code: "",
  secret: "",
  uri: "",
});

export const useAuthenticator = () => {
  const { authenticator } = useApi();

  const configure = () => {
    mustRemove.value = false;
    authenticator
      .configure()
      .then(({ data }) => {
        Object.assign(response2FA, data);
      })
      .catch(() => {
        mustRemove.value = true;
      });
  };

  return {
    configure,
    response2FA,
    mustRemove,
  };
};
