import { ApiService } from "@/services/api/ApiService";
import { TFinanceFilters, TFinanceRequest } from "@/types/finance";
import Qs from "qs";

export class Finance extends ApiService {
  public prepareParams(filters: TFinanceFilters) {
    return {
      ...filters,
      turnover_from: filters.turnover_from ? filters.turnover_from * 100 : null,
      turnover_to: filters.turnover_to ? filters.turnover_to * 100 : null,
      date_from: filters.date_from
        ? filters.date_from.format("YYYY-MM-DD")
        : null,
      date_to: filters.date_to ? filters.date_to.format("YYYY-MM-DD") : null,
    };
  }

  public list(page = 1, filters: TFinanceFilters, ordering: string | null) {
    return this.instance.get<TFinanceRequest>(`/admin/finance/`, {
      params: {
        page,
        ...this.prepareParams(filters),
        ordering,
      },
      paramsSerializer: (params) =>
        Qs.stringify(params, { indices: false, skipNulls: true }),
    });
  }
}
